/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-alert {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
     -moz-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  padding: 8px 15px;
  word-wrap: break-word;
  border-radius: 8px;
}
.ant-alert-content {
  -moz-box-flex: 1;
       flex: 1 1;
  min-width: 0;
}
.ant-alert-icon {
  margin-right: 8px;
}
.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}
.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}
.ant-alert-info .ant-alert-icon {
  color: #1890ff;
}
.ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}
.ant-alert-warning .ant-alert-icon {
  color: #faad14;
}
.ant-alert-error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}
.ant-alert-error .ant-alert-icon {
  color: #ff4d4f;
}
.ant-alert-error .ant-alert-description > pre {
  margin: 0;
  padding: 0;
}
.ant-alert-action {
  margin-left: 8px;
}
.ant-alert-close-icon {
  margin-left: 8px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.ant-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-close-text {
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-with-description {
  -moz-box-align: start;
       align-items: flex-start;
  padding: 15px 15px 15px 24px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px 15px;
}
.ant-alert-with-description .ant-alert-icon {
  margin-right: 15px;
  font-size: 24px;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-alert-message {
  color: rgba(0, 0, 0, 0.85);
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-motion-leave {
  overflow: hidden;
  opacity: 1;
  -webkit-transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -moz-transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert.ant-alert-motion-leave-active {
  max-height: 0;
  margin-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
.ant-alert.ant-alert-rtl {
  direction: rtl;
}
.ant-alert-rtl .ant-alert-icon {
  margin-right: auto;
  margin-left: 8px;
}
.ant-alert-rtl .ant-alert-action {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl .ant-alert-close-icon {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl.ant-alert-with-description {
  padding-right: 24px;
  padding-left: 15px;
}
.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
  margin-right: auto;
  margin-left: 15px;
}

.sms-code_sms-code__counter__2n1LB{color:#454545}
.sign-in-form_sign-in__form__1Kxu2{margin-top:50px}.sign-in-form_sign-in__btn-text__1t8Pt{padding:0}.sign-in-form_sign-in__btn-text__1t8Pt:hover{background-color:#f0f0f0}.sign-in-form_sign-in__btn__3jRwR{width:100%;margin-top:20px;background-color:#1f183f;border-color:#1f183f}.sign-in-form_sign-in__btn__3jRwR:hover{opacity:.95;background:#1f183f;border-color:#1f183f}.sign-in-form_sign-in__btn__3jRwR:focus{color:#fff;border-color:#168fff;background:#1f183f}
.forgot-password-form_forgot-password__form__3pRv3{margin-top:30px}.forgot-password-form_forgot-password__submit__327j1{width:100%;background-color:#1f183f;border-color:#1f183f}.forgot-password-form_forgot-password__submit__327j1:hover{opacity:.95;background:#1f183f;border-color:#1f183f}.forgot-password-form_forgot-password__submit__327j1:focus{color:#fff;border-color:#168fff;background:#1f183f}
.check-code_check-code__form__ZvNti{margin-top:30px}.check-code_check-code__note__hX0Ao{margin-bottom:30px;font-size:12px;color:rgba(0,0,0,.45)}.check-code_check-code__submit__1bazp{width:100%;background-color:#1f183f;border-color:#1f183f}.check-code_check-code__submit__1bazp:hover{opacity:.95;background:#1f183f;border-color:#1f183f}.check-code_check-code__submit__1bazp:focus{color:#fff;border-color:#168fff;background:#1f183f}.check-code_check-code__backward__2oL8g{width:100%}.check-code_check-code__backward__2oL8g:hover,.check-code_check-code__backward__2oL8g:focus{opacity:.8}
.sign-in_sign-in__3259b{min-width:280px;max-width:450px;width:100%;margin:0 20px;padding:50px 20px;border-radius:8px;background-color:#f0f0f0}@media(min-width: 768px){.sign-in_sign-in__3259b{padding:50px}}.sign-in_sign-in__header-wrapper__29jze{display:-moz-box;display:flex;-moz-box-pack:justify;justify-content:space-between;-moz-box-align:center;align-items:center;padding-bottom:10px;border-bottom:1px solid #bfbfbf}.sign-in_sign-in__header-wrapper__29jze h2{font-size:16px;font-weight:700}.sign-in_sign-in__header-wrapper__29jze button{padding:0}.sign-in_sign-in__header-wrapper__29jze button svg{width:24px;height:24px}
