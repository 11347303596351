@import "../../../../styles/base/variables";

.check-code {
  &__form {
    margin-top: 30px;
  }

  &__note {
    margin-bottom: 30px;
    font-size: 12px;
    color: $black45;
  }

  &__submit {
    width: 100%;
    background-color: $dark-blue;
    border-color: $dark-blue;

    &:hover {
      opacity: 0.95;
      background: $dark-blue;
      border-color: $dark-blue;
    }

    &:focus {
      color: $white;
      border-color: $blue;
      background: $dark-blue;
    }
  }

  &__backward {
    width: 100%;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }
}
