@import "../../../../styles/base/variables";

.sign-in {
  &__form {
    margin-top: 50px;
  }

  &__btn-text {
    padding: 0;

    &:hover {
      background-color: $gray-10;
    }
  }

  &__btn {
    width: 100%;
    margin-top: 20px;
    background-color: $dark-blue;
    border-color: $dark-blue;

    &:hover {
      opacity: 0.95;
      background: $dark-blue;
      border-color: $dark-blue;
    }

    &:focus {
      color: $white;
      border-color: $blue;
      background: $dark-blue;
    }
  }
}

