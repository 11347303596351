@import "../../../../styles/base/variables";

.forgot-password {
  &__form {
    margin-top: 30px;
  }

  &__submit {
    width: 100%;
    background-color: $dark-blue;
    border-color: $dark-blue;

    &:hover {
      opacity: 0.95;
      background: $dark-blue;
      border-color: $dark-blue;
    }

    &:focus {
      color: $white;
      border-color: $blue;
      background: $dark-blue;
    }
  }
}