@import "../../../styles/base/variables";
@import "../../../styles/mixins/breakpoints.scss";

.sign-in {
  min-width: 280px;
  max-width: 450px;
  width: 100%;
  margin: 0 20px;
  // min-height: 420px;
  padding: 50px 20px;
  border-radius: 8px;
  background-color: $gray-10;

  @include md {
    padding: 50px;
  }

  &__header {
    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid $gray-6;

      h2 {
        font-size: 16px;
        font-weight: 700;
      }
  
      button {
        padding: 0;
  
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
